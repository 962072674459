export const environment = {
  api_url: 'https://api-reakt360-development.stagingservers.co/api',
  firebase: {
    projectId: 'reakt360-e2c2a',
    appId: '1:690808891970:web:998ab7a00866e34039dbdb',
    storageBucket: 'reakt360-e2c2a.appspot.com',
    apiKey: 'AIzaSyCxdfe-_PJVQ6tn6KOiihAguxY7zd0hHvY',
    authDomain: 'reakt360-e2c2a.firebaseapp.com',
    messagingSenderId: '690808891970',
    measurementId: 'G-LYMG29TGCQ',
  },
  pusher: {
    key: '3c913022db6d5059c0c7',
    cluster: 'eu',
  },
};
